unless Date.now
  Date.now = ->
    new Date().getTime()

unless Date.today
  Date.today = ->
    new Date().setHours(0,0,0,0)

unless Date.todayAsDate
  Date.todayAsDate = ->
    new Date Date.today()

unless Date::addDays
  Date::addDays = (numDays) ->
    newDate = new Date @
    newDate.setDate newDate.getDate() + numDays
    newDate

unless Date::addMonths
  Date::addMonths = (numMonths) ->
    newDate = new Date @
    newDate.setMonth newDate.getMonth() + numMonths
    newDate

unless Date::addYears
  Date::addYears = (numYears) ->
    newDate = new Date @
    newDate.setYear newDate.getFullYear() + numYears
    newDate

# From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
unless String::startsWith
  Object.defineProperty String::, "startsWith",
    enumerable: false
    configurable: false
    writable: false
    value: (searchString, position) ->
      position = position or 0
      @lastIndexOf(searchString, position) is position

unless String::splitCamelCase
  Object.defineProperty String::, "splitCamelCase",
    enumerable: false
    configurable: false
    writable: false
    value: () -> @replace(/([A-Z])/g, ' $1')

unless String.containsText
  String.containsText = (str) ->
    return str?.trim().length > 0

unless String.isNullOrEmpty
  String.isNullOrEmpty = (str) ->
    return not (str?.trim().length > 0)

# This method is usefull for debugging certain serialization problems but
# Causes jquery to throw lots of errors so keep it commented out unless needed.
# unless Object::sz
#   Object::sz = -> angular.toJson(@).length